
import { Options, mixins } from "vue-class-component"
import CommonPage from "@/mixins/CommonPage"
import Layout from "@/components/Layout.vue"
import Title from "@/components/Title.vue"
import { surveyDisclaimerEvent } from "@/api/services/EventService"
import { $vfm } from "vue-final-modal"
import SurveyTermsModal from "@/components/Modal/SurveyTermsModal.vue"
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal.vue"
import CustomCheckbox from "@/components/Checkbox/CustomCheckbox.vue"
import ParticipantsModal from "@/components/Modal/ParticipantsModal.vue"
import PopperTooltip from "vue3-popper"
import Resize from "@/mixins/Resize"
@Options({
  components: {
    Layout,
    Title,
    SurveyTermsModal,
    PrivacyPolicyModal,
    CustomCheckbox,
    ParticipantsModal,
    PopperTooltip
  }
})
export default class SurveyDisclaimer extends mixins(CommonPage, Resize) {
  privacyPolicyModalName = "privacyPolicyModal"
  surveyTermsModalName = "surveyTermsModal"
  whatIsScreenerModalName = "whatIsScreenerModal"
  selectedItems: number[] = []

  get labels() {
    return [
      `This survey is exclusively for me${this.contactName ? `, ${this.contactName}` : ''}. No sharing allowed.`,
      "I’ll need to qualify for this survey before being eligible to complete it. Why?",
      "I need to complete the survey as soon as possible. Spots will run out.",
      "I’ll read questions carefully and answer  thoroughly. Quality is monitored.",
      "I have read and accept the survey terms and privacy policy."
    ]
  }

  get isAllChecked(): boolean {
    return this.selectedItems.length === this.labels.length
  }

  get contactName(): string {
    if (!this.contact?.fullNameCapitalized) return ""
    return this.contact?.isDoctor
      ? `Dr. ${this.contact?.fullNameCapitalized}`
      : this.contact?.fullNameCapitalized
  }

  onCheckboxChange(index: number): void {
    this.selectedItems.includes(index)
      ? this.selectedItems.splice(this.selectedItems.indexOf(index), 1)
      : this.selectedItems.push(index)
  }

  onModalOpenClick(name: string): void {
    $vfm.show(name)
  }

  async onStart(): Promise<void> {
    await surveyDisclaimerEvent(this.id)

    if (this.invite?.panelist || this.showTermsPage) {
      this.onAcceptInvite()
    } else {
      this.$router.push({
        name: "ConfirmName",
        query: {
          id: this.id
        }
      })
    }
  }
}
